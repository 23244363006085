export function moreWidthForVoting(){
    if($('#unvoted').length){
        $('.two-columns-for-voteing').css('max-width','100%');
    }
}

export function toggleNavItems(){
    // Get current URL
    let url = window.location.href;
    // Add active class to target link
    $("a.nav-link[href='" + url + "']").addClass('active');
}

export function hback() {
    $('.hback').on('click', function(){
        history.back();
    })
}