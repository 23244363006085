
export function toggleLikeStar(){
    $('.star-toggle').on( 'click',function(event){
            $(event.currentTarget).toggleClass('pulser').toggleClass('spinner')
            let interid = $(this).data('interid');
            $.post('/charts/toggleLikeStar',
                {
                    interid,
                })
                .done( function (response){
                    response = jQuery.parseJSON(response)
                    if(response[0] == 'success'){
                            let uid = $('.salut').data('cuid')
                            let ratings = response[1];
                            renderLikeStars(interid, ratings);
                            renderStarToggles(interid, ratings)
                            // Give animation some time to appear
                            setTimeout( function(){
                                $(event.currentTarget).toggleClass('pulser').toggleClass('spinner')
                                },
                                500
                            )
                        } else {
                            alert('Sorry, there went something wrong. Page will be reloaded.');
                            location.reload();
                        }
                    }
                );
        }    
    )
}

export function renderLikeStars(interid, ratings){
    let $current_li = $("li[data-interid='" + interid +"']");
    // Add data-userids for star-filter
    let userids = '';
    $.each( ratings, rating => {
            if(ratings[rating].liked == '1'){
                userids += ratings[rating].user_id + ':';
            }
        }
    )
    if(userids != '')
        $current_li.attr('data-userids',userids)


    // Handle  LIKE-Block in score container
    $("li[data-interid='" + interid +"'] > div.total-score").find('.likes-pack').remove()
    let likes_block = '<div class="likes-pack h6">';
    $.each( ratings, rating => {
            // console.log(ratings[rating])
            if(ratings[rating].liked == '1'){
                likes_block += '<p class="position-relative mb-0 star-container"><i class="fas fa-star text-gold"></i><span class="star-initials">'
                let initial = ratings[rating].user_name[0];
                likes_block += initial;
                likes_block += '</span></p>';
            }
        }
    )
    likes_block += '</div>';
    // console.log('renderLikeStars interid ', interid)
    $("li[data-interid='" + interid +"'] > div.total-score > div:first-child").append(likes_block)
}

export function renderStarToggles(interid, ratings){
    let cuid = $('.salut').data('cuid')
    let currentTarget = $("i[data-cuid='" + cuid + "'");
    $.each(ratings, function(idx, ele){
        if(ele.user_id != cuid)
            return
        if(ele.liked == 1){
            $(currentTarget).removeClass('far').addClass('fas')
        } else {
            $(currentTarget).removeClass('fas').addClass('far')
        }
    })
}

export function showStarToggles(interlist){
    $(interlist).children('li').each( function() {
        let interid = $(this).data('interid');
        getLikeStars(interid, renderStarToggles)
    })
}

export function getLikeStars(interid, renderLikeStarsCallback){
    // console.log('cccc',$("li[data-interid='" + interid +"'] > div.total-score > div"))
    $.post('/charts/getLikeStar',
        {
            interid
        },
        JSON
    ).done( function (response){
            response = jQuery.parseJSON(response)
            if(response[0] == 'success'){
                // console.log('getLikeStars response[1]: ', response[1])
                renderLikeStarsCallback(interid, response[1]);
            } 
        }
    ).fail( function (response){
        alert('Sorry, there went something wrong. Page will be reloaded.\n', response);
        location.reload();
    } );
}

export function showStars(interlist){
    $(interlist).children('li').each( function() {
        let interid = $(this).data('interid');
        // console.log('showStars: ',$(this).data('userids'))
        getLikeStars(interid, renderLikeStars)
    })
}
