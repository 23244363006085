import * as Stars from './like-stars.js';


export function updateSong(){
    $("#send-title").on('submit', function(event){
        event.preventDefault();
        let formInput = $(this).serialize();
        $(event.currentTarget).children('button').toggleClass('pulser').toggleClass('spinner')
        $.post('/charts/updateSong',
            formInput,
        ).done(function(response){ 
            if(response == 'success'){
                setTimeout( function(){
                        $(event.currentTarget).children('button').toggleClass('pulser').toggleClass('spinner') },
                    500
                )
            } else {
                alert('Sorry, there went something wrong. Page will be reloaded.');
                location.reload();
            }
        })
    })
}

export function deleteInter(){
    $("#del-inter").on('click', function(event){
        event.preventDefault();
        let formInput = $(this).serialize();
        $(event.currentTarget).children('button').toggleClass('pulser').toggleClass('spinner')
        $.post('/charts/updateSong',
            formInput,
        ).done(function(response){ 
            if(response == 'success'){
                setTimeout( function(){
                        $(event.currentTarget).children('button').toggleClass('pulser').toggleClass('spinner') },
                    500
                )
            } else {
                alert('Sorry, there went something wrong. Page will be reloaded.');
                location.reload();
            }
        })
    })
}


// Send URL and Title via AJAX to chart-controller
export function addUrl(){
    $("#add-url").on('submit', function(event){
        event.preventDefault();
        let empty_input = false
        $("#add-url :input").each(function(){
            if(emptyInput($(this)))
            empty_input = true
        })
        if(empty_input)
            return
        let formInput = $(this).serialize();
        $(event.currentTarget).children('button').toggleClass('pulser').toggleClass('spinner')
        $.post('/charts/addUrl',
            formInput,
        ).done(function(response){ 
            if(response == 'success'){
                setTimeout( function(){
                        $(event.currentTarget).children('button').toggleClass('pulser').toggleClass('spinner') },
                    500
                )
            } else {
                alert('Sorry: ' + response);
            }
            location.reload();
        })
    })
}

function emptyInput(cur_input){
    if(cur_input.val()){
        cur_input.removeClass('is-invalid')
        return false
    } else {
        cur_input.addClass('is-invalid')
        cur_input.next('span.invalid-feedback').html('Field must not be empty')
        return true
    }
}