export function fileUpload(){
    let uploadButton = $('#upload_button')
    let uploadInput = $('#upload_input')
    uploadButton.on('click', function(){
        // instead uploadInput.click()
        uploadInput.trigger('click');
    });
    uploadInput.on('change', function(){
        let pathToFile = uploadInput.val().replace('C:\\fakepath\\', '').trim().replaceAll(/ |'/g,'_')
        uploadButton.val(pathToFile)
    })
}