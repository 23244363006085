// pgsv short for Peter Grueger SONGVOTE / object as a namespace
// Sorry for global variables. Will be cleaned up .... 
const pgsv = {
    activeFilter: 'far',
    inactiveFilter: 'fas',
    offset: 0
}

import 'jquery';
require('jquery-ui-bundle');
require('jquery-ui-touch-punch');
import 'bootstrap';
import * as General from './modules/general.js';
import * as Upload from './modules/upload.js';
import * as Details from './detailsview.js';
import * as Stars from './like-stars.js';

$(document).ready(
    General.toggleNavItems(),
    General.hback(),
    General.moreWidthForVoting(),
    showScore("#sortable"),
    Stars.showStars("#totalscore"),
    Stars.showStarToggles('#sortable'),
    Stars.showStarToggles('#totalscore'),
    Stars.toggleLikeStar(),
    // scroll = $( "#sortable" ).sortable( "option", "scroll" ),
    pgsv.selectedUserIds = '',
    $('.like-filter').on('click', function(e){
        // console.clear()
        pgsv.curFilterSwitch = $(this)
        let userId = pgsv.curFilterSwitch.data('userid')
        updateFilter(userId)
        showSelection()
    }),
    $("#scrollTop").click(function () {
        $('html, body').animate({
            scrollTop: 0
        }, 'slow');
    }),
    pgsv.wHeight = $(window).height(),
    $(window).scroll(function () {
        if ($(this).scrollTop() > pgsv.wHeight * 0.8) {
            $("#scrollTop").show("slow");
        } else {
            $("#scrollTop").hide("slow");
        }
    }),
    Details.updateSong(),
    Details.addUrl(),
    Upload.fileUpload()
);

$('.draggable').draggable({
    connectToSortable: "#sortable",
    handle: ".score"
});

$("#sortable").sortable({
    out: function( event, ui ) {
        setScore("#sortable");
    },
    handle: ".score",
    scroll: false,
    scrollSensitivity: 2000,
    scrollSpeed: 50,
    // tolerance: "pointer",
    create: function(event, ui){
        // get height of scroll-areas (point to start scrolling) from CSS-variables
        pgsv.offset =  parseInt(getComputedStyle(document.documentElement,null).getPropertyValue('--scroll-area-height'));
    },
    sort: function( event, ui ) {
        let delay = 8; // delay to slow down scrolling
        let upperThreshold = $(window).scrollTop() + pgsv.offset;
        let lowerThreshold = $(window).scrollTop() + $(window).height() - pgsv.offset;
        $("body.votesongs").addClass('scroll-area')
        
        if(event.pageY < upperThreshold){
            $(window).scrollTop($(window).scrollTop() - ( event.pageY - $(window).scrollTop()) / delay)
        }
        
        if(event.pageY > lowerThreshold){
            $(window).scrollTop($(window).scrollTop() + (event.pageY - lowerThreshold) / delay)
        }
    },
    stop: function(event, ui){
        $("body.votesongs").removeClass('scroll-area')
    },
    receive: async function(event, ui){
        handleReceivedItems();
    }
});

function handleReceivedItems(){
    // remove placeholder from drop area
    if($('ul#sortable li').length == 3)
    $('.dummy').remove();
    
    // reload page if no unvoted items left
    if($('ul#draggable li').length == 0){
        location.reload()
    }
}

function updateFilter(userId){
    if(userId === 'ALL'  ){
        pgsv.selectedUserIds = '';
    } else if(userId === 'NOBODY'){
        if(pgsv.selectedUserIds != 'NOBODY'){
            pgsv.selectedUserIds = 'NOBODY';
            $('#starfilter').find('i').removeClass(pgsv.inactiveFilter).addClass(pgsv.activeFilter)
            $("div[data-userid='NOBODY']").find('i').removeClass('far text-muted').addClass('fas text-gold')
        } else {
            pgsv.selectedUserIds = '';
            // $('#starfilter').find('i').removeClass(pgsv.inactiveFilter).addClass(pgsv.activeFilter)
            $("div[data-userid='NOBODY']").find('i').removeClass('far text-gold').addClass('fas text-muted')
        }
    } else {
        pgsv.curFilterSwitch.children('i').toggleClass(pgsv.activeFilter).toggleClass(pgsv.inactiveFilter)
        $("div[data-userid='ALL']").find('i').removeClass(pgsv.inactiveFilter).addClass(pgsv.activeFilter)
        // toggle userid in array
        if(pgsv.selectedUserIds.includes(userId)){
            pgsv.selectedUserIds = pgsv.selectedUserIds.replace(userId,'')
        } else {
            pgsv.selectedUserIds += userId
        }
    }
    if(pgsv.selectedUserIds.length == 0){
        resetFilter()
    }
}

function resetFilter(){
    $('.like-filter').find('i').removeClass(pgsv.inactiveFilter).addClass(pgsv.activeFilter)
    $("div[data-userid='ALL']").find('i').removeClass(pgsv.activeFilter).addClass(pgsv.inactiveFilter)
    $('.interpretation').show()
}

function showSelection(){
    if(pgsv.selectedUserIds.length == 0){
        $('.interpretation').show()
        $('h1').html("The Charts&nbsp;(" + $('.interpretation').length + ")")
        return
    }
    
    $('.interpretation').hide();
    
    if(pgsv.selectedUserIds == 'NOBODY'){
        $('.interpretation').not('[data-userids]').show();
    }

    // .filter(Boolean) to get rid of empty array-element after split()
    var selectedUids = pgsv.selectedUserIds.split(':').filter(Boolean)

    $('[data-userids]').each(function(idx){
        var currentItem = $(this)
        var currentItemUids = $(this).data('userids')
        let allUidsMatching = true
        var selectedUids = pgsv.selectedUserIds.split(':').filter(Boolean)
        $.each(selectedUids, function(idx, uid){
            if(! currentItemUids.includes(uid)){
                allUidsMatching = false
            }
        })
        if( allUidsMatching ){
            currentItem.show()
        } 
    })
    // Put number of filtered items to header
    $('h1').html("Filtered&nbsp;(" + $('.interpretation:not([style*="display: none"])').length + ")")
}

function setScore(sortList) {
    const highscore = $('#sortable > li').length;
    let score_list = [];
    $(sortList).children('li').each( function(idx){
        let newScore = highscore - idx ;
        $(this).data('score', newScore );
        $(this).children('interpretation').addClass('bg-primary');
        score_list.push(
            {
                interid: $(this).data('interid'),
                score: $(this).data('score')
            }
        )
    });
    $.post('/charts/ratesong',{data:score_list} ).done(()=>{
        showScore(sortList)
    })
}

function showScore(sortList) {
    $(sortList).children('li').each( function(i){
        const score = $(this).data('score');
        $(this).children('.score').html( score + '<br>pts');
        if(score < 1){
            $(this).addClass('notRated').removeClass('bg-primary');
        } else {
            $(this).removeClass('notRated');
            $(this).children('.interpretation').addClass('bg-primary');
        }
        // interid = $(this).data('interid');
    })
}




// Adjust size of textarea while typing
$('body').on('keydown input', 'textarea[data-expandable]', function () {
    //Auto-expanding textarea
    this.style.removeProperty('height');
    this.style.height = (this.scrollHeight + 2) + 'px';
    }).on('mousedown focus', 'textarea[data-expandable]', function () {
        //Do this on focus, to allow textarea to animate to height...
        this.style.removeProperty('height');
        this.style.height = (this.scrollHeight + 2) + 'px';
    });

let scrollHeight = $("textarea[data-expandable]").prop("scrollHeight");
$("textarea[data-expandable]").height(scrollHeight);